<template>
    <div>
      <div v-if="isSmallScreen" class="pchome">
        <header>
		  <img class="imgTop" src="@/assets/topbg.png" @click="advertisement"/>
      </header>
        <nav class="navbar">
          <div class="a_title" v-for="(item,index) in navbarList" :key="item.id">
            <a @click="scrollToSection(index, 'themeId' + item.id, 'pc', item.id)">{{ item.themeName }}</a>
            <div class="line"></div>
          </div>
        </nav>
        <div class="dao" style="padding: 0 13vw;">
          <span id="shouye" @click="goHome">首页 > </span>
          <span id="zhen" @click="$router.push({path:'/article',query:{themeId: themeId}})"> {{ themeName }} </span>
          <span> > 正文</span>
        </div>
        <div class="z_title">{{ title }}</div>
        <div class="dis">
            <div class="riqi">
                日期：{{  createTime !== null ? createTime : '暂无' }}
            </div>
          <div>来源：{{ source }}</div>
          <div>字号：【 
            <span id="da" :class="{'xuan': fontSize === 24 }" @click="fontSize = 24">大</span
              >
            <span id="zhong" :class="{'xuan': fontSize === 16 }" @click="fontSize = 16">中</span>
            <span id="xiao" :class="{'xuan': fontSize === 12 }" @click="fontSize = 12">小</span>
          】
          </div>
        </div>
        <div class="zheng" :style="{ fontSize: fontSize + 'px' }" v-html="processedHtml(articleDetail)"></div>
        <!-- 附件 -->
        <div class="fuj" v-if="fileList.length > 0">附件</div>
            <div style="padding: 0 10%;padding-bottom: 100px;" v-if="fileList.length > 0">
                    <div class="box" v-for="item in fileList" :key="item.id">
                        <img src="@/assets/img/Mask group.png" />
                            <div class="bostitle">{{ item.name }}</div>
                                <span style="display: inline-block;width: 250px;">
                                  {{ (item.size / 1024 / 1024).toFixed(2) > 1 ? (item.size / 1024 / 1024).toFixed(2) + 'MB' :  + (item.size / 1024).toFixed(2) + 'KB'  }}
                                </span>
                                <span style="cursor: pointer;" @click="downloadFile(item)">下载</span>
                    </div>
        </div>
      <div class="bottombe">
        新华薪福：京ICP备2024057082号-1
      </div> 
      </div>
      <div v-else class="mobilehome">
        <img class="top" src="@/assets/img/top.png" @click="advertisement">
        <!-- 导航栏 -->
        <div class="mobileNavbar" id="Navbar">
          <ul id="ul">
              <!-- 获取动态数据后，先将第一项的visible设置为true，别的都设置为false,之后点击那个设置为true，别的设置为false-->
              <li v-for="(item,index) in navbarList" :key="item.id"><a :id="'link' + (index + 1)" :class="{'active': item.visible}" @click="scrollToSection(index ,'themeId' + item.id, 'mobile', item.id)">{{ item.themeName }}</a></li>
          </ul>
        </div>
        <div class="main">
            <div class="mobile_dao">
              <span id="shouye" @click="goHome">首页 ></span>
              <span id="myElement" @click="$router.push({path:'/article',query:{themeId: themeId}})"> {{ themeName }} </span>
              <span> > 正文</span>
            </div>
            <div class="title">
                {{ title }}
            </div>
            <div class="info">
                <div style="width: 45%;text-align: center;">日期：{{ createTime !== null ? createTime : '暂无' }}</div>
                <div style="width:55%;text-align:center;">来源：{{ source }}</div>
            </div>
            <div class="line"></div>
            <div class="articleDetail" style="font-size: 14px;" v-html="processedHtml(articleDetail)">
            </div>
            <!-- <div>
                <img class="sdlider-img" src="https://images.xinfuhuixuan.com/images/xinhuaxinfu/video.png">
            </div>
            <div class="hkt-text" >
                振兴领航，是新时代赋予我们的重要使命。乡村振兴不仅是经济社会的全面发展，更是国家长治久安和民族复兴的基石。因此，我们必须坚定信心，迎难而上，以领航者的姿态，引领乡村振兴的航程。 首先，振兴领航需要我们有清晰的战略规划和目标导向。我们要深入调研，了解乡村发展的实际需求和潜力，制定切实可行的振兴计划。同时，我们要紧紧围绕乡村振兴战略的总要求，以产业兴旺、生态宜居、乡风文明、治理有效、生活富裕为目标，全面推进乡村振兴。
            </div>
            <div class="hkt-text">
                  首先，一位农业专家为大家详细介绍了当前农业发展的新形势和新机遇。他指出，随着科技的不断进步和政策的持续支持，农业产业正迎来前所未有的发展机遇。同时，他也提醒大家要关注农业生产中的生态环境保护和可持续发展问题。
            </div>
            <div class="video_player">
                <video controls poster="https://images.xinfuhuixuan.com/images/xinhuaxinfu/video.png" src="https://images.xinfuhuixuan.com/images/xinhuaxinfu/960W.mp4"></video>
            </div> 
            <div style="margin-bottom: 16vw;" class="hkt-text">
                  首先，一位农业专家为大家详细介绍了当前农业发展的新形势和新机遇。他指出，随着科技的不断进步和政策的持续支持，农业产业正迎来前所未有的发展机遇。同时，他也提醒大家要关注农业生产中的生态环境保护和可持续发展问题。
            </div> -->
        </div>
        <div class="fj">
                    <div class="fj-top" v-if="fileList.length > 0">
                        <img style="width: 1.8vw;height: 3.5vw;margin-right: 1.2vw;" src="@/assets/img/Polygon.png">
                        <div style="width: 12vw;height: 3.8vw;line-height: 3.8vw;font-size: 0.85rem;">附件</div>
                        <hr style="background-color: #BDC0C3;height: 1px;width: 80vw;">
                    </div> 
                    <div class="fj-bottom">
                        <div class="fj-bottom-item" v-for="item in fileList" :key="item.id">
                            <img style="margin-right: 2vw;height: 6vw;width: 6vw;" src="@/assets/img/Mask group.png" >
                            <div style="width: 57vw;font-size: 0.7rem;overflow: hidden;white-space: nowrap;text-overflow: ellipsis;">
                              {{ item.name }}
                            </div>
                            <div style="display: flex;">
                                <div  class="fj-bottom-text" style="width: 15vw;">
                                  {{ (item.size / 1024 / 1024).toFixed(2) > 1 ? (item.size / 1024 / 1024).toFixed(2) + 'MB' :  + (item.size / 1024).toFixed(2) + 'KB'  }}
                                </div>
                                <div id="copy_btn" class="fj-bottom-text" style="width: 10vw;color: #0F6ABE;" @click="downloadFile(item)">下载</div>
                            </div>
                        </div>
                    </div>
        </div>
        <div class="footer" v-if="toTop" @click="goTop">
            <div style="margin-left: 82vw;" id="goTop">
              <img style="height: 13vw;width: 13vw;" src="@/assets/img/Group 2814.png">
            </div>
        </div>
        <div class="bah">
            新华薪福：京ICP备2023028182号-1
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios'
  import ClipboardJS from 'clipboard'
  // axios.defaults.baseURL = 'https://api.market.muyouhuo.com/xinfuhuixuan/front/xinhuaxinfu/theme/'

  export default{
    data(){
      return{
        // 决定显示pc端还是mobile端
        isSmallScreen: true,
        // 导航栏数据
        navbarList:[],
        themeName:'',
        title: '',
        articleDetail:'', // 文章详情
        createTime: '', // 时间
        intro: '', // 简介
        source: '', // 来源
        fontSize: 16, // 初始字体大小
        themeId:'',
        fileList: [], // 附件信息
        autoPlayInterval: null,
        toTop: false,
        clipboard: null
      }
    },
    async created(){
      this.getNavbar()
      this.title = this.$route.query.title
      const data = await axios.get('https://api.market.muyouhuo.com/xinfuhuixuan/front/xinhuaxinfu/theme/getArticleById?articleId=' + this.$route.query.articleId)
      if(data.data.code === 200){
        if(data.data.extend.data.list.length > 0){
          this.fileList = data.data.extend.data.list
        }
        this.articleDetail = data.data.extend.data.content.replace(/<span style="/gi, match => {
                // 使用正则表达式匹配 <span> 标签的开头，并添加 style 属性
                // 注意：这种方法可能不总是安全的，因为它假设了 HTML 的格式  
                return match.replace(/<span style="/, '<span style="word-break: break-all;')
        })
        this.articleDetail = this.articleDetail.replace(/<video/gi, match => {
                // 使用正则表达式匹配 <span> 标签的开头，并添加 style 属性
                // 注意：这种方法可能不总是安全的，因为它假设了 HTML 的格式  
                return match.replace(/<video/, '<video style="width: 100% !important;" autoplay')
        })
        this.title = data.data.extend.data.title
        this.intro = data.data.extend.data.intro
        this.createTime = data.data.extend.data.createTime
        this.source = data.data.extend.data.source
        this.themeId = data.data.extend.data.themeId
        this.navbarList.forEach(item => {
          if(this.themeId === item.id) {
            this.themeName = item.themeName
          }
        })
      }
    },
    mounted() {
      // 决定显示哪个元素
      this.screenWidth()
      window.addEventListener('resize', this.innerWidth)
      // 控制滑动顶部按钮是否显示
      window.addEventListener('scroll', this.handleScroll)
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.handleScroll)
    },
    methods:{
      processedHtml(aaa){
        // if(this.isSmallScreen === false){
          // 假设我们想要基于某些条件改变样式  
          let html = aaa
            // html = html.replace(/!important/gi, match => {  
            //   // 使用正则表达式匹配 <span> 标签的开头，并添加 style 属性  
            //   // 注意：这种方法可能不总是安全的，因为它假设了 HTML 的格式  
            //   return match.replace(/!important/, '')
            // })
          return html
        // }
      },
      // 物理宽度
      screenWidth(){
        const isInWeChatMiniProgram = /MicroMessenger/.test(navigator.userAgent)
        if(isInWeChatMiniProgram){
          this.isSmallScreen = false
          return
        }
        let width = screen.width
        if (width >= 550) {
          this.isSmallScreen = true
        } else {
          this.isSmallScreen = false
        }
      },
      // 窗口宽度
      innerWidth(){
        const isInWeChatMiniProgram = /MicroMessenger/.test(navigator.userAgent)
        if(isInWeChatMiniProgram){
          this.isSmallScreen = false
          return
        }
        let width = window.innerWidth
        if (width >= 550) {
          this.isSmallScreen = true
        } else {
          this.isSmallScreen = false
        }
      },
      async getNavbar(){
        const res = await axios.get('https://api.market.muyouhuo.com/xinfuhuixuan/front/xinhuaxinfu/index/getTheme')
        if(res.data.code === 200){
          const data = res.data.extend.data
          data.forEach((item,index) => {
            if(item.themeType !== 1){
              this.navbarList.push(item)
            }
          })
          this.navbarList.forEach((item,index) => {
            // if(index === 0){
            //   item.visible = true
            // }else{
              item.visible = false
            // }
          })
        }
      },
      // 跳转首页
      scrollToSection(index,elId,text,id){
        if(text === 'pc'){
          this.$router.push({
            name: 'home',
            params:{
              index: index + 1,
              elId: elId,
              text: text,
              id: id
            }
          })
        }else{
          this.$router.push({
            name: 'home',
            params:{
              index: index + 1
            }
          })
        }
      },
      goHome(){
        this.$router.push({path: '/home'})
      },
      // 跳转广告页
      // 跳转广告页
      async advertisement(id){
        let articleId = ''
        const res2 = await axios.get('https://api.market.muyouhuo.com/xinfuhuixuan/front/xinhuaxinfu/index/getArticleTitle')
        if(res2.data.code === 200){
          const data = res2.data.extend.data
          data.forEach(item => {
            if(item.themeType == 1){
              articleId = item.data[0].articleId
            }
          })
        }
        this.$router.push({
          path: '/advertisement',
          query:{
            articleId: articleId
          }
        })
      },
      // 下载文件
      downloadFile(file){
        const isInWeChatMiniProgram = /MicroMessenger/.test(navigator.userAgent)
        if(isInWeChatMiniProgram){
          // 初始化 clipboard.js 实例
          this.clipboard = new ClipboardJS('#copy_btn', {
            text: () => {
              // 返回要复制的文本
              return file.url
            },
          })
          // 添加成功和失败的回调
          this.clipboard.on('success', (e) => {
            // console.log('文本已复制到剪贴板')
            // 清除选中文本
            e.clearSelection()
          })
          this.clipboard.on('error', (e) => {
            // console.error('复制失败')
          })
          this.$message.success('文件地址已复制到剪贴板，请到浏览器下载')
        }else{
          // 创建一个临时的a标签  
          const a = document.createElement('a')
          a.style.display = 'none'
          a.href = file.url  
          // 设置下载的文件名，这里假设URL已经包含了文件名，或者你可以手动设置
          a.download = file.name // 你可以根据需要修改文件名  
          // 将a标签添加到文档中  
          document.body.appendChild(a)
          // 触发点击  
          a.click()
          // 清理：移除a标签并释放URL对象  
          document.body.removeChild(a)
        }
      },
      // 控制返回顶部按钮显示/隐藏
      handleScroll() {
        const scrollTop = window.pageYOffset || document.documentElement.scrollTop
        const screenHeight = window.innerHeight || document.documentElement.clientHeight
        if (scrollTop > screenHeight) {  
          this.toTop = true
          // console.log(true)
        } else {  
          this.toTop = false
        }  
      },
      // 滚动到顶部
      goTop(){
        window.scrollTo({  
          top: 0,  
          behavior: 'smooth'  
        })
      },
    }
  }
  </script>
  
  <style lang="less" scoped>
  // pc样式
  body, html {margin: 0;padding: 0;font-family: Arial, sans-serif;font-size:24px;}
			header {color: #fff;text-align: center;}
			header img{width: 100%; height: 100%;}
			/* 原 */
			.navbar {margin-top: -6px;display: flex;justify-content: space-around;background-color:#fff;box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); /* 水平偏移量，垂直偏移量，模糊半径，阴影颜色 */}
			.navbar a { font-size:26px; width:100%; height:100%; color: black; text-decoration: none; position: relative; /* 添加相对定位 */display: flex; justify-content: center; align-items: center;}
			.navbar a::after {content: ""; position: absolute;left: 15%; /* 下划线从按钮内容的中心开始 */bottom: 0;width: 0;height: 2px;background-color: red;transition: width 0.5s ease; /* 添加过渡效果 */}
			.a_title1 a{font-weight: bold;}
			.navbar a:hover {color:#50B0E4;}
			/* .navbar a:hover::after { width: 70%;} */
			/* .a_title{ width:14.2%; height:50px;display: flex;justify-content: center;align-items: center;}
			.line{height:30%;width:1px;background-color:black;} */


			/* 葛 */
			.navbar {display: flex;justify-content: space-around;background-color:#005099;box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1); width: 80%;padding: 0 10%;}
			.a_title{width:14.2%;height:50px;cursor: pointer;}
			.navbar a {font-size:22px;width:100%;height:90%;color: #fff;text-decoration: none;position: relative;display: flex;justify-content: center;align-items: center;}
			/* .navbar a::after {content: "";position: absolute;left: 15%; bottom: 0;width: 0;height: 2px;background-color: red;transition: width 0.5s ease; } */
			/* .navbar a:hover {color:#50B0E4;} */
			/* .navbar a:hover::after {width: 70%; } */
			.line{height:2px;width:80px;background-color:#fff;margin-left: calc(50% - 40px);display: none;}
			.dao{font-size: 20px; cursor: pointer;line-height: 80px;padding-left: 30px;text-align: left;}
			.z_title{text-align: center;font-size: 34px;}
			.dis{display: flex;justify-content: space-around;align-items: center;font-size: 20px;width: 72%; margin-left: 14%;line-height: 60px;margin-top: 30px;border-bottom: 2px solid #BDC0C3;}
		    .xuan{color: red;}
			.iconimg{width: 100%;height: 35vw; display: flex;justify-content: center;margin-top: 40px;}
			.imgs{width: 60vw;height: 100%;}
			.zheng{padding:15px 14vw;white-space:pre-wrap;font-size: 22px;color: #666666;text-indent: 2em;margin-bottom: 70px;}
			#da{cursor: pointer;}
			#zhong{cursor: pointer;}
			#xiao{cursor: pointer;}
      .fuj{margin: 0 70px; background-color: #f3f3f3;font-size: 24px;padding: 15px 5%; box-sizing: border-box;text-align: left;}
      .box{display: flex;align-items: center;margin-top: 20px;font-size: 22px;}
      .box img{width: 26px;padding-right: 20px;}
      .boxleft div{font-size: 16px;display: flex;}
      .bostitle{width: 50%;}
			.bottombe{height: 70px;width: 100%; background-color: #005099;font-size: 18px;color: #FFFFFF;line-height: 70px;text-align: center;position: fixed;bottom: 0;}
  

    // mobile样式
    .top{
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 70px;
        z-index: 9;
        }
        .mobileNavbar {  
        position: fixed;
        /* top: 15vw; */
        top: 70px;
        overflow-x:scroll;
        scrollbar-width: none; /* Firefox 浏览器 */  
        -ms-overflow-style: none; /* IE 和 Edge 浏览器 */  
        white-space: nowrap;
        width: 100vw;
        height: 50px;
        background-image: url('@/assets/img/Rectangle\ 1092.png'); 
        z-index: 9;
    }  
    .mobileNavbar ul {
        padding: 0;
        margin: 0;
        display: flex;
        list-style-type: none ;
        height: 50px;
    }
    .mobileNavbar ul li {  
        padding: 0;
        flex-shrink: 0;
        width: 25vw; 
        height: 100%;
        box-sizing: border-box;
    } 
    .mobileNavbar li a {  
        display: block;  
        color: rgba(255, 255, 255, 0.8);
        text-align: center;
        /* padding: 14px 0;   */
        text-decoration: none;
        font-size: 0.8rem;
        line-height: 50px;
    }
        .active{
          color: #fff !important;
          font-size: 0.95rem !important;
          font-weight: bold !important;
          background: url('@/assets/img/Rectangle\ 1183.png') no-repeat !important;
          background-position: center 80% !important;
          background-size: 13vw 1px !important;
        }
        .main{
          margin: 120px auto 0;
          width: 95vw;
        }
        .mobile_dao{
          width: 100%;
          height: 40px;
          line-height: 40px;
          text-align: left;
          color: #666666;
          font-size: 0.8rem;
          margin-bottom: -10px;
        }
        .breadCrumb{
            height: 10vw;
            line-height: 10vw;

            text-align: left;
            font-size: 0.7rem;
            color: #666666;
        }
        .title{
            // height: 10vw;
            // line-height: 10vw;
            padding: 3px 0;
            text-align: center;
            font-size: 0.83rem;
            font-weight: bold;
        }
        .info{
            margin-bottom: 10px;
            display: flex;
            align-items: center;
            font-size: 0.65rem;
            color: #666666;
        }
        .line{
            margin: 10px 0;
            height: 1px;
            width: 100%;
            background-color: #BDC0C3;
        }
        .sdlider-img {
            width: 100%;
            height: 50vw;
            border-radius: 5px;
        }
        .hkt-text{
            margin-top: 4vw;
            text-indent:1.5rem;
            font-size: 0.75rem;
            color: #666666;
        }
        /* video{
            border-radius: 5px;
            width: 100%;
        } */
        .ctrlVideo{
            height: 10vw;
            background: url('@/assets/img//Rectangle\ 1154.png') no-repeat;
            background-size: 100% 100%;
        }
        /* 视频模块 */
        .video_player {
            position: relative;
            width: 95vw;
            height: 45vw;
            margin: 5vw auto 15vw;
        }
        .video_player video{
            width: 100%;
        }

        .footer{
          position: fixed;
          bottom: 20vw;
        }
        .articleDetail{
          // margin-bottom: 18vw;
          ::v-deep p{
            img{
              width: 100%;
            }
          }
        }
        .zheng{
          // margin-bottom: 70px;
          ::v-deep p{
            // text-align: center !important;
            img{
              margin: 0 auto;
              // text-align: center;
              // width: 1200px !important;
              // height: px !important;
            }
            span{
              // color: yellow !important;
              // word-break: break-all;
            }
          }
        }
        .fj{
          margin: 0 auto 35vw;
          width: 95vw;
        }
        .fj-top{
          display: flex;
          margin:10vw 0 4vw;
          align-items: center;
          width: 100%;
        }
        .fj-bottom{
            // height: 40vw;
        }
        .fj-bottom-item{
            display: flex;
            align-items: center;
            height: 7.5vw;
        }
        .fj-bottom-text{
            text-align: right;
            font-size: 0.75rem;
        }
        /* 备案号 */
        .bah{
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100vw;
            height: 15vw;
            line-height: 15vw;
            text-align: center;
            background-color: #005099;
            font-size: 0.8rem;
            color: #FFFFFF;
        }
  </style>
  